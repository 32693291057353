import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import GridItem from '../components/GridItem';
import { fixPlaceholder, getAnimations } from '../utils/functions';

const RightWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 !important;
  height: 100%;
  & > div {
    ${getAnimations()}
  };
  padding-top: 3.5rem;
  @media (min-width: ${props => props.theme.breakpoint.l}) {
    padding-top: 0rem;
  }
`;

const Column = styled.div`
  height: 100%;
`;

const MusicVideoPage = ({ data: { musicVideos, placeHolder } }) => (
  <Layout isIndex={false}>
    <Column>
      <Helmet>
        <title>Music Videos</title>
      </Helmet>
      <RightWrapper columns={2}>
        {musicVideos.edges.map(c => (
          <GridItem
            uid={c.node.uid}
            key={c.node.uid}
            image={fixPlaceholder(c.node.data, placeHolder)}
            alt={c.node.data.title.text}
            title={c.node.data.title.text}
            author={c.node.data.editor}
          />
        ))}
      </RightWrapper>
    </Column>
  </Layout>
);

export default MusicVideoPage;

MusicVideoPage.propTypes = {
  data: PropTypes.shape({
    musicVideos: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
    placeHolder: PropTypes.object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query MusicVideoQuery {
    musicVideos: allPrismicProject(
      sort: { fields: [data___taxonomy], order: [ASC] }
      filter: { data: { project_type: { eq: "Music Video" } } }
    ) {
      edges {
        node {
          uid
          data {
            taxonomy
            hero_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 900, maxHeight: 506, quality: 90, cropFocus: ENTROPY) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            title {
              text
            }
            editor
          }
        }
      }
    }
    placeHolder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, maxHeight: 506, quality: 90, cropFocus: ENTROPY) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
